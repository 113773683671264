import Button from "../components/_button";
// import Common from "../components/_common";
import HttpService from "../http/_httpServices";
import { IListPostType } from "../interfaces/_intercase-datas";
import { IArgsConfig, IPostTypes } from "../interfaces/_interface-query-args";
import { PostTypeResponse } from "../interfaces/_response";


class ListPostType {
  private BASE_URL = window.vars.ajax_url;
  http: HttpService;
  button: Button;
  body: HTMLBodyElement;
  datas: IListPostType[] = [];

  mainFilterOpen = 'fso';
  actions = {
    listPostType: 'list_post_type',
    more_info: 'more_info',
    copy_count: 'update_copy_count',
  };

  classes = {
    list: '.js-list-post-type',
    append: '.js-append-results',

    form: '.js-filter-form',
    orderTitle: '.js-order-title',
    change: '.js-filter-change',
    // range: '.js-filter-range',
    // rangeMin: '.js-range-min',
    // rangeMax: '.js-range-max',
    count: '.js-list-count',
    reset: '.js-reset-filter',

    loadMore: '.js-list-loadmore',
    cta: '.js-list-cta',
    loader: '.js-loader',

    moreInfo: '.js-more-info',

    moreInfoNoAJAX: '.js-more-info-no-ajax',

    show_code: '.js-show-code',
    copy_code: '.js-copy-code',
    
  }

  lists = [];
  appends = [];

  forms = [];
  changes = [];
  orderTitles = [];
  // ranges = [];
  // rangesValues = [];
  counts = [];
  resets = [];

  btnsOpenFilter= [];
  slidingFilters = [];


  loadMoreBtns = [];
  loaders = [];

  btnsMoreInfo = [];

  btnsMoreInfoNoAJAX = [];

  btnsShowCode = [];
  btnsCopyCode = [];
  btnsYesNo = [];
  tooltip= [];

  clickCount: number[] = [];
  clickTypes   : ('module' | 'args')[] = [];

  initialModule: IPostTypes[];


  constructor() {
    this.onInit();
  }

  onInit() {
    this.body = document.querySelector('body');
    this.http = new HttpService();
    this.button = new Button();
   

    this.lists = [].slice.call(document.querySelectorAll(this.classes.list));

    this.getDatas();
  }

  getDatas() {
    if (this.lists) {
      this.lists.map((list, i:number) => {
        this.pushData(list, i);
      });
    }
  }

  pushData(list: HTMLElement, i:number) {
    const args: IArgsConfig = JSON.parse(list.dataset.args);
    this.datas.push({
      view: list.dataset.view,
      page_posts: parseInt(list.dataset.page_posts),
      total_posts: parseInt(list.dataset.total_posts),
      args: args,
      module: JSON.parse(list.dataset.module),
      post_type: list.dataset.type,
      post_ids: list.dataset.posts,
      ordered: parseInt(list.dataset.ordered),
      package: parseInt(list.dataset.package),
    });

    this.appends.push(list.querySelector(this.classes.append));
    this.forms.push(list.querySelector(this.classes.form));
    this.changes.push([].slice.call(list.querySelectorAll(this.classes.change)));
    this.orderTitles.push([].slice.call(list.querySelectorAll(this.classes.orderTitle)));
    this.counts.push([].slice.call(list.querySelectorAll(this.classes.count)));
    this.resets.push([].slice.call(list.querySelectorAll(this.classes.reset)));
    this.loadMoreBtns.push(list.querySelector(this.classes.loadMore));
    this.loaders.push(list.querySelector(this.classes.loader));

    this.btnsMoreInfo[i] = [].slice.call(list.querySelectorAll(this.classes.moreInfo));

    this.btnsMoreInfoNoAJAX[i] = [].slice.call(list.querySelectorAll(this.classes.moreInfoNoAJAX));

    this.btnsShowCode[i] = [].slice.call(list.querySelectorAll(this.classes.show_code));
    this.btnsCopyCode[i] = [].slice.call(list.querySelectorAll(this.classes.copy_code));

    this.clickTypes.push('args');
    this.clickCount.push(0);

    this.onChangeFilter(this.changes[i], i);
    this.onClickLoadMore();
    this.onClickResetFilter();
    this.onClickMoreInfo();

    this.onClickMoreInfoNoAJAX();

    this.openFilter(i);
    this.closeTargetList(i);
    this.setupSearchFilter(i);

    if (this.btnsShowCode[i] && this.btnsCopyCode[i]) {
      this.onClickShowCode();
      this.onClickCopyCode();
    }
  }

  beforeGetList(i, abort = true) {
    const offset: number = this.datas[i].page_posts * this.clickCount[i];

    this.loadMoreBtns[i].style.display = 'none';
    this.loaders[i].style.display = 'block';

    if ( offset + this.datas[i].page_posts >= this.datas[i].total_posts && this.datas[i].total_posts > 0) {
      this.datas[i].args.posts_per_page = this.datas[i].total_posts - offset;
    }

    if (this.clickTypes[i] === 'args' && this.datas[i].args) {
      this.datas[i].args.offset = offset;
    }

    if (this.clickTypes[i] === 'module') {
      this.datas[i].module.list_type = '3';
      this.datas[i].module.offset = offset;
    }

    this.getList({
      action: this.actions.listPostType,
      data: {
        view: this.datas[i].view,
        args: this.clickTypes[i] === 'args' ? JSON.stringify(this.datas[i].args) : JSON.stringify(this.datas[i].module),
        type: this.clickTypes[i],
        total: this.clickTypes[i] === 'args' ? this.datas[i].total_posts : this.datas[i].module.number_of_results,
        post_type: this.datas[i].post_type,
        ordered: this.datas[i].ordered,
        package: this.datas[i].package,
      }
    }, i, abort)
  }

  getList(data: {
    action: string, data: {
      view: string,
      args: string,
      type: string,
      total: number,
      post_type: string,
      ordered: number,
      package: number,
    }
  }, i: number, abort = true) {
    const pack = data.data.package;
    this.http.request("post", data, abort).
      then((res) => {
        const data: PostTypeResponse = JSON.parse(res.data);
        this.appends[i].insertAdjacentHTML('beforeend', data.html);
        this.loaders[i].style.display = 'none';
        const btnsInfo = [].slice.call(this.lists[i].querySelectorAll('.js-state')).slice(this.datas[i].args.offset * 2);

        if (data.more) {
          this.loadMoreBtns[i].style.display = null;
        } else {
          const cta = this.lists[i].querySelector(this.classes.cta);

          if (cta) {
            cta.style.display = null;
          }
        }

        if (this.counts[i]) {
          this.counts[i].map((count: HTMLElement) => {
            count.innerHTML = data.found.toString();
          });
        }

        this.lists[i].dataset.package = pack;
        const index = this.lists.length - 1;
        this.onChangeFilter(this.changes[index], index);

        this.btnsMoreInfo[i] = [];

        this.setMoreInfo([].slice.call(this.lists[i].querySelectorAll(this.classes.moreInfo)));

        this.btnsMoreInfoNoAJAX[i] = [];
        this.setMoreInfoNoAJAX([].slice.call(this.lists[i].querySelectorAll(this.classes.moreInfoNoAJAX)));

        this.btnsShowCode[i] = [];
        this.showCode([].slice.call(this.lists[i].querySelectorAll(this.classes.show_code)));

        this.btnsCopyCode[i] = [];
        this.copyCode([].slice.call(this.lists[i].querySelectorAll(this.classes.copy_code)));
      })
      .catch(error => {
        console.log(error);
      });
  }

  onChangeFilter(changeMore, i: number) {
    if (changeMore.length) {
      changeMore.map(item => {
        const handleRadioChange = () => {
          // Find all radio buttons within the same form or container
          const allRadios = document.querySelectorAll(`input[type="radio"][name="${item.name}"]`);
          allRadios.forEach((radio: HTMLInputElement) => {
            // Update the 'active' class on the parent element based on the checked state
            if (radio.checked) {
              radio.parentElement.classList.add('active');
            } else {
              radio.parentElement.classList.remove('active');
            }
          });
          this.changeFilter(item, i);
        };

        if (item.tagName === 'BUTTON') {
          item.addEventListener('click', () => {
            this.changeFilter(item, i);
          });
        } else if (item.name === 's') {
          item.addEventListener('keyup', () => {
            this.changeFilter(item, i);
          });
        } else if (item.classList.contains('js-order-title')) {
          item.addEventListener('click', () => {
            // Toggle between "title" and "title_desc" values
            if (item.value === 'title') {
              item.value = 'title_desc';
              item.nextElementSibling.textContent = 'Z-A'; // Assuming the label is the next sibling
            } else {
              item.value = 'title';
              item.nextElementSibling.textContent = 'A-Z';
            }
            handleRadioChange(); // Call the refactored change handler
          });
        } else if (item.type === 'radio') {
          item.addEventListener('change', handleRadioChange); // Use the refactored change handler
        } else {
          item.addEventListener('change', () => {
            if (item.checked) {
              item.parentElement.classList.add('active');
            } else {
              item.parentElement.classList.remove('active');
            }
            this.changeFilter(item, i);
          });
        }

      });
    }
  }

  changeFilter(item, i: number) {
    const list = this.lists[i];
    const cta: HTMLElement = list.querySelector(this.classes.cta);
    const form = this.forms[i];
    const formData = new FormData(form);
    const multiSelection = [
      'hosting_packages_listing',
      'categories_listing',
      'processing_listing',
      'region_listing',
      'specific_countries',
    ];

    this.appends[i].innerHTML = '';
    this.datas[i].module = JSON.parse(list.dataset.module);
    this.datas[i].module.allowed_in = '0';
    formData.forEach((value: string, key: string) => {
      if (value !== '0' && value !== '') {
        if (key === 'date') {
          const values = formData.getAll(key);
          this.datas[i].module['date'] = {
            month: values[0],
            year: values[1]
          }
        }
        else if (multiSelection.includes(key)) {
          if (key === 'specific_countries') {
            this.datas[i].module.allowed_in = '2';
          }
          this.datas[i].module[key] = formData.getAll(key);
        } else {
          this.datas[i].module[key] = value;
        }
      }
    });

    if (this.datas[i].post_type == 'post') {
      if (item.name === 'hosting_packages_listing') {
        // Update the dataset.package value to the new one
        this.datas[i].package = item.value;
      }
    }

    if (cta) {
      cta.style.display = 'none';
    }
    this.clickCount[i] = 0;
    this.clickTypes[i] = 'module';
    this.resets[i].map((btn: HTMLButtonElement) => btn.style.display = null);
    this.beforeGetList(i);
  }

  onClickResetFilter() {
    if (this.resets) {
      this.resets.map((resets: HTMLButtonElement[], i: number) => {
        resets.map((reset: HTMLButtonElement) => {
          this.resetFilter(reset, i);
        });
      })
    }
  }

  resetFilter(reset: HTMLElement, i: number) {
    reset.addEventListener('click', () => {
      const list = this.lists[i];
      const form = list.querySelector(this.classes.form);
      const resetBtns = [].slice.call(list.querySelectorAll(this.classes.reset));
      const cta = list.querySelector(this.classes.cta);

      if (this.datas[i].post_type == 'post') {
        this.datas[i].package = JSON.parse(reset.dataset.package);
      }
      
      this.appends[i].innerHTML = '';
      this.clickCount[i] = 0;
      this.clickTypes[i] = 'args';
      form.reset();

      // if (this.ranges[i]) {
      //   this.ranges[i].map((range, j:number) => {
      //     range.noUiSlider.set(range.dataset.start);
      //     this.rangesValues[i][j].innerHTML = parseInt(range.noUiSlider.get());
      //   });
      // }

      // if (this.randerChoices[i]) {
      //   this.randerChoices[i].forEach((choice, j: number) => {
      //     choice.clearStore();
      //     choice.setChoices(this.choicesValues[i][j]);
      //   });
      // }

      if (cta) {
        cta.style.display = 'none';
      }

      this.resetAllFilters(list);

      resetBtns.map((btn: HTMLButtonElement) => btn.style.display = 'none');
      this.beforeGetList(i);
    });
  }

  resetAllFilters(list) {
    // Reset all search inputs
    const searchInputs = list.querySelectorAll('.js-search-options') as NodeListOf<HTMLInputElement>;
    searchInputs.forEach((input) => {
      input.value = ''; // Clear the search input value
    });

    // Reset all filter checkboxes and radio buttons
    const filterInputs = list.querySelectorAll('.js-filter-change') as NodeListOf<HTMLInputElement>;
    const defaultInput = list.querySelector('.js-filter-change.js-default') as HTMLInputElement;
    filterInputs.forEach((input) => {
      if (input.type === 'radio') {
        input.checked = false; // Uncheck all radios
        input.parentElement.classList.remove('active'); // Remove 'active' class from all
      }
    });
  
    // Check the default input and add 'active' class to its parent
    defaultInput.checked = true;
    defaultInput.parentElement.classList.add('active');
  
    // Additional logic to handle "A-Z" and "Z-A" toggle
    if (defaultInput.classList.contains('js-order-title')) {
      // If the default is "A-Z", ensure it's set to "A-Z" and not "Z-A"
      defaultInput.nextElementSibling.textContent = 'A-Z';
    }

    const filterTaxElements = list.querySelectorAll('.filter_tax');
    filterTaxElements.forEach((filterTax) => {
      filterTax.classList.remove('active');
    });

    const filterTaxLis = list.querySelectorAll('.filter_tax li') as NodeListOf<HTMLLIElement>;
    filterTaxLis.forEach((li) => {
      li.style.display = 'block';
      li.classList.remove('active');
    });

    const filterTaxButtons = list.querySelectorAll('button') as NodeListOf<HTMLButtonElement>;
    filterTaxButtons.forEach((btn) => {
      btn.classList.remove('active');
    });
  }

  onClickLoadMore() {
    if (this.loadMoreBtns) {
      this.loadMoreBtns.forEach((btn: HTMLButtonElement, i: number) => {
        this.setClickLoadMore(btn, i);
      });
    }
  }

  setClickLoadMore(btn: HTMLButtonElement, i:number) {
    this.clickTypes[i] = 'args';
    this.clickCount[i] = 0;

    if (btn) {
      btn.addEventListener('click', () => {
        this.clickCount[i]++;
        this.beforeGetList(i)
      })
    }
  }

  onClickMoreInfo() {
    if (this.btnsMoreInfo) {
      this.btnsMoreInfo.forEach((items) => {
        this.setMoreInfo(items);
      });
    }
  }


  onClickMoreInfoNoAJAX() {
    if (this.btnsMoreInfoNoAJAX) {
      this.btnsMoreInfoNoAJAX.forEach((items) => {
        this.setMoreInfoNoAJAX(items);
      });
    }
  }

  setMoreInfo(items) {
    if (items) {
      items.forEach((btn: HTMLButtonElement) => {
        btn.addEventListener('click', (e) => {
          if (btn.classList.contains('loaded')) {
            this.toggleMoreInfo(e);
          } else if (!btn.classList.contains('loading')) {
            this.getMoreInfo(e);
          }
        });
      });
    }
  }

  setMoreInfoNoAJAX(items) {
    if (items) {
      items.forEach((btn: HTMLButtonElement) => {
        btn.addEventListener('click', (e) => {
          if (!btn.classList.contains('active')) {
            btn.classList.add('active');
            this.toggleMoreInfo(e);
          } else {
            btn.classList.remove('active');
          }
        });
      });
    }
  }

  toggleMoreInfo(e) {
    const target = e.target;
    const item = target.closest('.lh_item') as any;
    item.classList.toggle('opened');


    if (item.classList.contains('opened')) {
      target.textContent = target.dataset.close;
    } else {
      target.textContent = target.dataset.open;
    }
  }

  getMoreInfo(e) {
    const btn = e.target as HTMLButtonElement;
    const list = btn.closest(this.classes.list) as HTMLElement;
    btn.classList.add('loading');
    
    this.http.request("post", {
      action: this.actions.more_info,
      data: {
        post_id: btn.dataset.post_id,
        package: list.dataset.package,
      }
    }).
      then((res) => {
        const more_info = JSON.parse(res.data);
        const item = btn.closest('.lh_item') as HTMLElement;
        btn.classList.remove('loading');
        btn.classList.add('loaded');
        item.classList.add('opened');

        if (item.classList.contains('opened')) {
          btn.textContent = btn.dataset.close;
        } else {
          btn.textContent = btn.dataset.open;
        }
        item.insertAdjacentHTML('beforeend', more_info);        
      })
      .catch((error) => {
        console.log(error);
      });
  }

  openFilter(i: number) {
    const list = this.lists[i];
    const btnOpen = list.querySelectorAll('.filter_more');
    if (btnOpen) {
      btnOpen.forEach((btn) => {
        btn.addEventListener('click', () => {
          if (!list.classList.contains('fso')) {
            list.classList.add('fso');
          } else {
            list.classList.remove('fso');
          }
        });
      });
    }
  }

  closeTargetList(i: number) {
    const list = this.lists[i];
    document.addEventListener('click', (e) => {
      const target = e.target as HTMLElement;
      if (!target.classList.contains('filter_column') && !target.closest('.filter_column') && !target.closest('.filter_more')) {
        list.classList.remove('fso');
      }
    });
  }

  setupSearchFilter(i: number) {
    const list = this.lists[i];
    const filterColumn = list.querySelector('.filter_column');
  
    if (!filterColumn) {
      return;
    }
    const taxonomies = filterColumn.querySelectorAll('.filter_tax');
  
    taxonomies.forEach((tax: HTMLElement) => {
      const searchInput = tax.querySelector('.js-search-options') as HTMLInputElement;
      const listItems = tax.querySelectorAll('li');
  
      if (searchInput) {
        searchInput.addEventListener('input', () => {
          const searchTerm = searchInput.value.trim().toLowerCase();
  
          listItems.forEach((item: HTMLElement) => {
            const id = item.querySelector('.js-filter-change')?.getAttribute('id')?.toLowerCase() || '';
            const shouldDisplay = id.includes(searchTerm) || searchTerm === '';
  
            item.style.display = shouldDisplay ? 'block' : 'none';
          });
        });
      }
    });
  }

  onClickShowCode() {
    if (this.btnsShowCode) {
      this.btnsShowCode.forEach((items) => {
        this.showCode(items);
      });
    }
  }

  showCode(items) {
    if (items) {
      items.forEach((btn: HTMLButtonElement) => {
        btn.addEventListener('click', () => {
          btn.style.display = "none";
        })
      })
    }
  }

  onClickCopyCode() {
    if (this.btnsCopyCode) {
      this.btnsCopyCode.forEach((items) => {
        this.copyCode(items);
      });
    }
  }

  copyCode(items) {
    if (items) {
      items.forEach((input: HTMLInputElement, i:number) => {
        input.addEventListener('click', () => {
          const range = document.createRange();
          range.selectNode(input);
          const selection = window.getSelection();
          selection.removeAllRanges();

          this.tooltip[i] = input.previousElementSibling as HTMLDivElement;
          const couponID = parseInt(this.tooltip[i].dataset.id);
          let copyCount = parseInt(this.tooltip[i].dataset.copy_count);
          copyCount = copyCount + 1;

          this.tooltip[i].classList.add('active');

          setTimeout(() => {
            this.tooltip[i].classList.remove('active');
          }, 2000);

          selection.addRange(range);
          document.execCommand('copy');
          selection.removeAllRanges();

          this.updateCopyCountInACF({
            action: this.actions.copy_count,
            data: {
              coupon_id: couponID,
              copy_count: copyCount,
            }
          }, i);
        })
      })
    }
  }

  updateCopyCountInACF(data: { action: string, data: Record<string, unknown> }, i:number) {
    this.http.request("post", data).
    then((res) => {
      const data: Response = JSON.parse(res.data);
    }).catch((error) => {
      console.log(error);
    });
  }
}

new ListPostType();